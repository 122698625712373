import React from "react"
import Layout from "../../Components/pageComponents/contentContainer"
import { Container } from "reactstrap"

import { useStaticQuery, graphql } from "gatsby"
import SolutionInfoSection from "../../Components/pageComponents/solutionsInfoSection"
import Img from "gatsby-image"


import style from "./styles.module.css"

export default function DataCenter() {

  const Image = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "solutions/Data Center/Data Center Banner.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      data1: file(relativePath: {eq: "solutions/Data Center/1st Data Center Stock.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      data2: file(relativePath: {eq: "solutions/Data Center/2nd Data Center Stock.png"}) {
        childImageSharp {
          fixed(width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }`
  )
  const description = "A Data Center is the most challenging solution to design. It is the facility used to house application and network equipment for the organization’s Information and Communication Technology (ICT) needs. It includes thorough design of its Mechanical and Electrical Infrastructure such as back-up power supply system (UPS), ventilation & cooling system (HVAC), and redundant networking connections as well as its fire suppression and its physical security system. Fastlink’s experience, expertise, and competence coupled with its cost effective, tried and tested ICT products; let us make your Data Centre the most reliable and comprehensive mission critical facility.";

  return (
    <Layout image={Image.banner.childImageSharp.fluid}
      title="Data Center">

      <SolutionInfoSection heading="Solutions We Offer" title="Data Center" description={description}
        img={Image.data1.childImageSharp.fluid} />
      <Container
        fluid
        // className="pt-5 pb-5 pl-5 pr-5"
        style={{
          background: `var(--FSC-YELLOW)`,
          paddingTop: `4rem`,
          paddingBottom: `3rem`,
        }}
      >
        {/* <h1 className="mx-auto mb-4 heading1 p-3 text-center">
                <span></span>
              </h1> */}
        <div className={style.audioVideoContainer}>
          <div>
            <Img fixed={Image.data2.childImageSharp.fixed} />
          </div>
          <div style={{ marginLeft: `40px` }}>
            <h3 style={{ marginBottom: `15px` }}>Typical Components of a Data Center</h3>
            <ul>
              <li>The Computing Hardware</li>
              <li>Racks</li>
              <li>Network and Security</li>
              <li>Connectivity</li>
              <li>Power</li>
              <li>Cabling System</li>
              <li>UPS</li>
              <li>Precision Air Conditioning Unit (PACU)</li>
              <li>Fire Detection and Alarming System (FDAS)</li>
            </ul>
          </div>


        </div>
      </Container>

    </Layout>

  )
}